<script>
import Banner from "@/views/components/Banner.vue";
import { tabsOption } from "@/views/artisticActivity/moke";
import LeftMenu from "@/views/components/LeftMenu.vue";
import Breadcrumb from "@/views/components/Breadcrumb.vue";
import ViewDetailsImage from "@/views/components/ViewDetailsImage.vue";
import LearnMore from "@/views/components/LearnMore.vue";
import notData from "@/views/components/Not_data.vue";

export default {
  name: "index",
  components: {
    notData,
    LearnMore,
    Breadcrumb,
    LeftMenu,
    Banner,
    ViewDetailsImage
  },
  data() {
    return {
      tabsOption: tabsOption,
      currentTab: {}, // 当前选中的标签页数据
      breadcrumbOption: [], // 面包屑
      childSelect: {} // 页面选中的数据
    };
  },
  mounted() {
    this.currentTab = this.tabsOption[0];
    this.childSelect = this.tabsOption[0];
    this.checkTab(this.currentTab.key);
  },
  methods: {
    /**
     * 切换一级目录
     * @param key
     */
    checkTab(key) {
      this.childSelect = {};
      // 初始化三级目录
      const tabsOption = this.tabsOption;
      tabsOption.map(item => {
        if (item.key === key) {
          this.currentTab = item;
          // 添加面包屑
          const currentOption = [
            {
              key: "artisticActivity",
              toPath: "/artisticActivity"
            }
          ];
          currentOption.push({
            key: item.key,
            title: item.title,
            toPath: "/artisticActivity"
          });
          this.breadcrumbOption = currentOption;
          this.checkChildSelect(item.menuChild[0], 2);
        }
      });
    },
    /**
     * 切换二级，三级目录保存数据
     * @param item
     * @param menuCount 面包屑的位数
     */
    checkChildSelect(item, menuCount) {
      // 添加面包屑 （暂时关闭）start
      const currentOption = this.breadcrumbOption;
      const isFlog = currentOption.some(o => o.key === item.key);
      if (!isFlog) {
        const newOption = {
          key: item.key,
          title: item.title,
          toPath: "/artisticActivity?sel=" + item.key
        };
        currentOption.splice(menuCount, menuCount + 1, newOption);
        this.breadcrumbOption = currentOption;
      }
      // 添加面包屑 end
      this.childSelect = item;
    }
  }
};
</script>

<template>
  <div>
    <Banner
      :bannerSrc="require('@/assets/banner/artisticActivity.jpg')"
      :tabs="tabsOption"
      :selTab="currentTab.key"
    />
    <div class="padding-comm-warp _content_warp activity_content_warp">
      <div class="left">
        <LeftMenu :currentTab="currentTab" :childSelect="childSelect" />
      </div>
      <div class="right">
        <Breadcrumb :breadcrumbList="breadcrumbOption" />
        <div
          class="content"
          v-if="childSelect.descList && childSelect.descList.length !== 0"
        >
          <div
            v-for="item in childSelect.descList"
            :key="item.key"
            class="content_item"
          >
            <div class="content_left">
              <el-image :src="item.img" fit="scale-down" lazy />
            </div>
            <div class="content_right">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="time">
                <el-image
                  :src="require('@assets/artActivity/time_1x.png')"
                  fit="scale-down"
                  lazy
                /><span>{{ item.time }}</span>
              </div>
              <div class="desc" v-html="item.desc"></div>
              <div class="save_info">
                <ViewDetailsImage />
              </div>
            </div>
          </div>
          <div v-if="childSelect.descList && childSelect.descList.length !== 0">
            <LearnMore />
          </div>
        </div>
        <div v-else>
          <notData />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../index.scss";

.activity_content_warp {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.activity_content_warp .right .content .content_item {
  display: flex;
  margin-bottom: 50px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  .content_left {
    .el-image {
      width: 528px;
    }
  }

  .content_right {
    padding: 20px;
    width: 100%;
    position: relative;
    .title {
      font-size: 1.2rem;
    }
    .time {
      font-size: 0.8rem;
      margin-top: 2rem;
      span {
        margin-left: 6px;
      }
      .el-image {
        width: 11px;
        height: 11px;
        cursor: pointer;
      }
    }
    .desc {
      font-size: 0.8rem;
      margin-top: 1rem;
      line-height: 24px;
    }
    .save_info {
      position: absolute;
      bottom: 1rem;
    }
    .view_details {
      margin-left: 0px !important;
    }
  }
}
</style>
